import React, { useState, useContext, useEffect } from 'react';
import { StyleSheet, View, Text, Button, FlatList, SafeAreaView } from 'react-native';
import { uploadExerciseVideo } from '../VideoUploadServices';
import {getExerciseList,getAllSubjectIdsAndNamesOnUnlabeledVideos} from '../services'
import FileUploadComponent from './FileUploadComponent';
import DropdownComponent from './DropdownComponent'; 
import { DataContext } from '../contexts/DataContext';
import { AccountContext } from './Account';
import Navbar from './Navbar';
import { UploadContext } from '../contexts/UploadContext';
import ToastNotification from './ToastNotification';

const VideoUploadPage = ({ navigation }) => {
  const { authToken, getUserSession } = useContext(AccountContext);
  const { addUploadTask, updateUploadTask } = useContext(UploadContext);
  const [userAuthToken, setUserAuthToken] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [exerciseList, setExerciseList] = useState([]);
  const [subjectArray, setSubjectArray] = useState([]);
  // const { exerciseList = [{ label: '', value: '' }], subjectArray = [{ label: '', value: '' }] } = useContext(DataContext);
  const [exerciseValue, setExerciseValue] = useState(null);
  const [idValue, setIdValue] = useState(null);

  useEffect(() => {
    let exerciseArray = []
    getUserSession().then((session) => {
      console.log('user session called on VideoUploadPage: ', session.accessToken.jwtToken)
      setUserAuthToken(session.accessToken.jwtToken);
      getExerciseList(session.accessToken.jwtToken).then((exercises)=>{
        console.log(exercises)
  
        for (var tKey in exercises) exerciseArray.push({ label: exercises[tKey]["exercise_name"], value: exercises[tKey]["exercise_key"] });
        setExerciseList(exerciseArray)
        console.log(exerciseArray)
        setUserAuthToken(session.accessToken.jwtToken)
        // setIsLoading(false)
      })
      getAllSubjectIdsAndNamesOnUnlabeledVideos(session.accessToken.jwtToken).then((subjects) => {
        var subjectArr=[]
        for (var subject of subjects) {
          subjectArr.push({
              label: `${subject.user_name} - ${subject.subject_id}`,
              value: subject.subject_id
          });
        }
        setSubjectArray(subjectArr);
        console.log('Fetched subjects:', subjects);
        console.log('subjectArr: ', subjectArr);

        }).catch(error => {
          toast(`API Call "Fetch Subjects" Failed: Please report this issue!`,
          { render: `${error}`,
          type: 'success',
          autoClose: 5000,})
          // console.error('Error fetching subjects:', error);
          // throw error;
        }); 
    });
  }, []);

  const handleFilesChange = (files) => {
    setUploadedFiles(files);
    console.log(files);
    console.log(exerciseList);
  };

  const uploadFiles = () => {
    if (!userAuthToken) {
      console.error('No auth token found');
      return;
    }

    if (uploadedFiles.length === 0) {
      alert('no files to upload!');
      return;
    }

    uploadedFiles.forEach((file) => {
      const taskId = `${file.uri}-${Date.now()}`;
      const uploadTask = {
        id: taskId,
        exerciseValue,
        idValue,
        userAuthToken,
        file,
        progress: 0,
      };

      addUploadTask(uploadTask);

      uploadExerciseVideo(
        exerciseValue,
        idValue,
        '',
        '',
        '',
        '',
        userAuthToken,
        file.uri,
        false,
        (progress) => {
          updateUploadTask(taskId, progress);
        }
      ).then(() => {
        updateUploadTask(taskId, 1);
      }).catch((error) => {
        console.error(error);
      });
    });

    setUploadedFiles([]);
  };

  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.container}>
        <Navbar navigation={navigation} username={null} />
        <Text style={styles.heading}>Upload Your Video File (upload 1 only please)</Text>
        <FileUploadComponent onFilesChange={handleFilesChange} />
        <View style={styles.uploadedFilesContainer}>
          <Text style={styles.subHeading}>Files in VideoUploadPage:</Text>
          <FlatList
            data={uploadedFiles}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item }) => <Text style={styles.fileListItem}>{item.name}</Text>}
          />
        </View>
        <Button title="Upload files to S3 servers" onPress={uploadFiles} />
      </View>
      <View style={styles.dropdownContainer}>
        <DropdownComponent
          options={exerciseList}
          type="Exercises"
          value={(value) => setExerciseValue(value)}
          containerStyle={{ zIndex: 10 }}
          zIndex={10}
        />
        <DropdownComponent
          options={subjectArray}
          type="Subjects"
          value={(value) => setIdValue(value)}
          containerStyle={{ zIndex: 1 }}
          zIndex={1}
        />
      </View>
      <ToastNotification />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    marginTop: 200,
    flex: 1,
    backgroundColor: '#fff',
  },
  dropdownContainer: {
    marginTop: 300,
    maxWidth: '40%',
    flex: 1,
    flexDirection: 'row',
    alignContent: 'center',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  heading: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  uploadedFilesContainer: {
    marginTop: 20,
    width: '100%',
    paddingHorizontal: 20,
  },
  subHeading: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  fileListItem: {
    marginBottom: 5,
  },
});

export default VideoUploadPage;