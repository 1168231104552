import { StyleSheet, View } from 'react-native';
import {useState} from "react";
import UserPool from "./UserPool";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";

const SignUp = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");

    let attributeList = [
        new CognitoUserAttribute({
            Name: 'given_name', Value: name
        })
    ]

    const onSubmit = (e) => {
        e.preventDefault();
        UserPool.signUp(email, password, attributeList, null, (err, data) => {
            if (err) {
                console.error(err);
            }
            console.log(data);
        });
    };

    return (
        <View onSubmit={onSubmit} style={styles.container}>
            <label>Name</label>
            <input
                    value = {name}
                    onChange = {(e) => setName(e.target.value)}/>
            <label>Email</label>
            <input
                    value = {email}
                    onChange = {(e) => setEmail(e.target.value)}/>
            <label>Password</label>
            <input
                    value = {password}
                    onChange = {(e) => setPassword(e.target.value)}/>
            <button type="submit">Sign Up</button>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexGrow: 1,
      padding: 10,
      margin: 10,
      width: '100%',
      flexDirection:'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
    }
})

export default SignUp