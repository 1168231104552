
'use strict';
import { StyleSheet } from 'react-native';


//style sheet for the application, imported in other components
const style = StyleSheet.create({
    textinput:{
        borderWidth: 1,
        height: '10%',
        width: '90%',
        margin: '5%',
        borderRadius: 5,
        backgroundColor: 'white',
        borderStyle: 'solid',
    },
    loginButton:{
        alignSelf: 'center',
        backgroundColor: 'blue',
        borderRadius: 10,
        padding: 15,
        width: '40%',
        
    },  
    h3:{
        fontSize: 20
    },
    buttonText:{
        textAlign: 'center', color: 'white', fontSize: 20
    },
    centerHome:{
        marginTop: '10%',
    },
    centerLogin: {
        marginTop: '20%',
        borderRadius: 10,
        shadowColor: 'black',
        boxShadow: "1px 3px 1px #9E9E9E",
        shadowOffset: {
            width: 0,
            height: 3
        },
        shadowOpacity: '50%',
        
    },
    alwaysred: {
        backgroundColor: 'red',
        height: 100,
        width: 100,

    },
});

export default style; 