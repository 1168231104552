import { StyleSheet, View } from "react-native";
import React, { useState } from "react";
import Login from './Login';
import { Account } from './Account';
import style from "./style";
import Navbar from './Navbar';

const LoginScreen = ({navigation}) => {
    const [authToken, setAuthToken] = useState();
    const [authenticated, setAuthenticated] = useState();
    const [username, setUsername] = useState();

    const changeStatus = (auth, authToken, newUserId) => {
        let newToken = authToken.jwtToken;
        setAuthToken(newToken);
        setAuthenticated(auth);
        setUsername(newUserId);
    };

    return (
        <View style={styles.container}>
            <Navbar navigation={navigation} username={username} />
            {/* <Navbar authenticated={authenticated} username={username} /> */}
            <View style={style.centerLogin} elevation={5}>
                <Account>
                    <Login login={changeStatus} />
                </Account>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        
    },
    centerLogin:{
        maxWidth: "50%", 
        maxHeight: "50%"
    }
});

export default LoginScreen;
