import React, { useState, useImperativeHandle, useEffect, forwardRef } from "react";
import { View, StyleSheet, Text, Button } from 'react-native';
import LabelRepInput from "./LabelRepInput";

const LabelRepRow = forwardRef((props, ref) => {
  const [selectedExercise, setSelectedExercise] = useState("");
  const [number, setNumber] = useState(0);
  const [counts, setCounts] = useState([]);
  const [repetitions, setRepetitions] = useState([]);
  const [toggle, setToggle] = useState(false);

  const forceRerender = () => {
    console.log("forceRerender called")
    setToggle(prevToggle => !prevToggle)
  }

  useEffect(() => {
    console.log("repetitions updated: ", repetitions);
    forceRerender();
  }, [repetitions]);

  useEffect(()=>{
    console.log("forceRerender finished")
  }, [toggle])

  useImperativeHandle(ref, () => ({
    resetTable() {
      setCounts([]);
      setRepetitions([]);
      setNumber(0);
      props.setUnsavedData(false);
    },
    logStart(time) {
      console.log("logStart called on videoTime: ", time);
      let newReps = [...repetitions];
      let idxOfFirstRepWithoutStartTime = -1;

      for (let idx = 0; idx < newReps.length; idx++) {
        if (newReps[idx].start === "") {
          idxOfFirstRepWithoutStartTime = idx;
          break;
        }
      }

      if (idxOfFirstRepWithoutStartTime === -1) {
        console.log("need to create a new row, b/c rows currently have start times")
        //make sure current data is valid before creating new row
        checkRepetitionDataValidity();
        let newRep = { id: newReps.length + 1, start: String(time), stop: "", label: "", comment: "" };
        newReps.push(newRep);
        let newNumber = number + 1;
        setNumber(newNumber);
        setCounts([...counts, newNumber]);
        setRepetitions(newReps);
      } else {
        console.log("no need to create new row, row #",idxOfFirstRepWithoutStartTime+1," has no start time")
        //need to do this to create a new array reference, tricking react into doing a rerender
        let newRep={id: idxOfFirstRepWithoutStartTime+1, start: String(time), stop: String(newReps[idxOfFirstRepWithoutStartTime].stop), label: newReps[idxOfFirstRepWithoutStartTime].label, comment: newReps[idxOfFirstRepWithoutStartTime].comment}
        newReps.splice(idxOfFirstRepWithoutStartTime, 1, newRep)
        setRepetitions(newReps);
      }

      props.setUnsavedData(true);
    },

    logStop(time) {
      console.log("logStop called on videoTime: ", time);
      let newReps = [...repetitions];
      let idxOfFirstRepWithoutStopTime = -1;

      for (let idx = 0; idx < newReps.length; idx++) {
        if (newReps[idx].stop === "") {
          idxOfFirstRepWithoutStopTime = idx;
          break;
        }
      }

      if (idxOfFirstRepWithoutStopTime === -1) {
        console.log("need to create a new row, b/c rows currently have stop times")
        //make sure current data is valid before creating new row
        checkRepetitionDataValidity();
        let newRep = { id: newReps.length + 1, start: "", stop: String(time), label: "", comment: "" };
        newReps.push(newRep);
        let newNumber = number + 1;
        setNumber(newNumber);
        setCounts([...counts, newNumber]);
        setRepetitions(newReps);
      } else {
        console.log("no need to create new row, row #",idxOfFirstRepWithoutStopTime+1," has no stop time")
        //need to do this to create a new array reference, tricking react into doing a rerender
        let newRep={id: idxOfFirstRepWithoutStopTime+1, start: String(newReps[idxOfFirstRepWithoutStopTime].start), stop: String(time), label: newReps[idxOfFirstRepWithoutStopTime].label, comment: newReps[idxOfFirstRepWithoutStopTime].comment}
        newReps.splice(idxOfFirstRepWithoutStopTime, 1, newRep)
        setRepetitions(newReps);
      }

      props.setUnsavedData(true);
    }
  }));


  const addRow = () => {
    //make sure current data is valid before creating new row
    checkRepetitionDataValidity();
    console.log("row added");
    const newNumber = number + 1;
    setNumber(newNumber);
    setCounts([...counts, newNumber]);

    const emptyRep = {
      id: newNumber,
      start: "",
      stop: "",
      label: "",
      comment: "",
    };

    setRepetitions([...repetitions, emptyRep]);
    props.setIsRepStartButtonActive(true);

  };

  const checkRepetitionDataValidity = () => {
    for(let idx=0;idx<repetitions.length;idx++)
      {
        let currRep=repetitions[idx];
        if(currRep.start !== "" && currRep.stop !== "" && Number(currRep.start)>=Number(currRep.stop))
          {
            alert(`WARNING: In row #${idx+1}, please make sure repStop time occurs after repStart time`)
            break;
          }
      }
  }

  const removeRow = () => {
    console.log("row removed");
    if (number === 0) {
      setNumber(0);
      setRepetitions([]);
      setCounts([]);
      return;
    }

    const newCounts = counts.slice(0, -1);
    const newRepetitions = repetitions.slice(0, -1);
    setNumber(newCounts.length);
    setCounts(newCounts);
    setRepetitions(newRepetitions);
    props.setIsRepStartButtonActive(true);

  };

  const update = (data) => {
    console.log("update function called");
    const newRepetitions = [...repetitions];
    const index = newRepetitions.findIndex(rep => rep.id === data.id);
    if (index !== -1) {
      newRepetitions[index] = data;
    } else {
      newRepetitions.push(data);
    }
    setRepetitions(newRepetitions);
    props.setUnsavedData(true);
    console.log("repetitions: ", newRepetitions);
  };

  const submit = () => {
    if (number === 0) {
      alert('Please enter data');
      return;
    }

    const finalReps = repetitions.filter(e => e && e.start !== null && e.stop !== null);

    //make sure current data is valid before submitting
    for (let rep of finalReps) {
      if (Number(rep.start) >= Number(rep.stop)) {
        alert("Start time must occur before stop time");
        return;
      }
    }
    console.log("finalReps: ", finalReps);

    const data = {
      sessionID: "",
      exerciseType: selectedExercise,
      repetitions: finalReps,
      labelerName: "",
      date: ""
    };
    console.log("data: ", data);
    submitSession(data);
    props.setUnsavedData(false);
    props.setIsRepStartButtonActive(true);
  };

  function submitSession(data) {
    props.submitLabels(data);
  }

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: 'row', padding: 0, margin: 0 }}>
        <View style={styles.head}>
          <Text>Rep No.</Text>
        </View>
        <View style={styles.head}>
          <Text>Start</Text>
        </View>
        <View style={styles.head}>
          <Text>Stop</Text>
        </View>
        <View style={styles.head}>
          <Text>Label</Text>
        </View>
        <View style={{ display: 'table-cell', textAlign: 'center', border: '1px solid black', width: '20%', marginRight: 0 }}>
          <Text>Comment</Text>
        </View>
      </View>

      <View>
        {counts.map((count) => (
          <LabelRepInput
            key={count}
            count={count}
            style={styles.box}
            onUpdate={update}
            currentData={repetitions[count-1]}
          />
        ))}
      </View>
      
      <View style={styles.buttonView}>
        <Button title="+(add)" onPress={addRow}></Button>
        <Button title="-(delete)" onPress={removeRow}></Button>
        <Button title="Submit" onPress={submit}></Button>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  buttons: {
    backgroundColor: 'red',
  },
  buttonView: {
    marginTop: 10,
  },
  container: {
    flex: 1,
    flexGrow: 1,
    padding: 10,
    marginTop: 10,
    marginRight: 10,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    display: 'table',
  },
  head: {
    display: 'table-cell',
    textAlign: 'center',
    border: '1px solid black',
    borderRightWidth: 0,
    height: 'auto',
    width: '20%'
  },
  box: {
    display: 'table-cell',
    textAlign: 'left',
    border: '1px solid black',
    borderRightWidth: 0,
    borderTopWidth: 0,
    height: 'auto',
    width: '20%',
    padding: 10
  }
});

export default LabelRepRow;