import React, {useState, useEffect} from 'react';
import DropDownPicker from 'react-native-dropdown-picker';
import {View,StyleSheet, Text} from 'react-native';

//https://hossein-zare.github.io/react-native-dropdown-picker-website/docs/usage

const DropdownComponent = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  var [placeholder, setPlaceholder] = useState('Exercise Type');
  const [items, setItems] = useState([]);


  useEffect(() => {
    if (props.type) {
      setPlaceholder(props.type);
    }
    if (props.options) {
      setItems(props.options);
    }
  }, [props.type, props.options]);

  const valueChanged = (value) => {
    setValue(value)
    props.value(value) 
  }
  

  return (
      
        
        <DropDownPicker
        multiple=
      {(props.type === 'Feedback')?(true):(false)}
        textStyle={styles.text}
        dropDownContainerStyle={[styles.list, props.containerStyle]}
        listItemContainer={{
            width: '100%'
          }}
        style={[styles.dropdown, props.containerStyle]}
        //bottomOffset={100}
        //maxHeight={100}
        placeholderStyle={{
            //   backgroundColor: "#fff",
            fontWeight: "bold"
          }}
        open={open}
        placeholder={placeholder}
        value={value}
        items={items}
        setOpen={setOpen}
        setValue={(value) => valueChanged(value)}
        setItems={setItems}
        dropDownDirection='BOTTOM'
        />
   
  );
}


const styles = StyleSheet.create({
  list: {
    backgroundColor: 'white',
    borderWidth: 0,
    alignItems: 'center',
    padding: 10,
    margin: 5,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 2,
    elevation: 3,
    maxHeight: 300,
  },
  text: {
    fontSize: 16,
    margin: 2,
    alignItems: 'center',
    color: '#333',
  },
  dropdown: {
    padding: 15,
    margin: 10,
    alignItems: 'center',
    backgroundColor: '#fff',
    width: '100%',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 2,
    elevation: 3,
  },
  placeholder: {
    fontWeight: 'bold',
    color: '#888',
  },
});

export default DropdownComponent;