import React from "react";
import { StyleSheet, View, Text, Button, SafeAreaView } from "react-native";
import PropTypes from "prop-types";

export default class Modal extends React.Component {
  onClose = (e) => {
    this.props.onClose();
  };

  onConfirm = (e) => {
    this.props.onConfirm();
  };

  onCancel = (e) => {
    this.props.onCancel();
  };

  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <SafeAreaView>
        <View style={styles.modal}>
          <Text style={styles.content}>{this.props.text}</Text>
          <View style={styles.actions}>
            {this.props.onConfirm && (
              <Button title="OK" onPress={(e) => this.onConfirm(e)} />
            )}
            {this.props.onCancel && (
              <Button title="Cancel" onPress={(e) => this.onCancel(e)} />
            )}
            <Button title="Close" onPress={(e) => this.onClose(e)} />
          </View>
        </View>
      </SafeAreaView>
    );
  }
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  text: PropTypes.string,
};

const styles = StyleSheet.create({
  modal: {
    width: 500,
    backgroundColor: "white",
    border: "1px solid #ccc",
    transition: "1.1s ease-out",
    boxShadow: "-2rem 2rem 2rem rgba(0, 0, 0, 0.2)",
    filter: "blur(0)",
    transform: "scale(1)",
    opacity: 1,
    visibility: "visible",
  },
  content: {
    padding: "1rem",
  },
  actions: {
    borderTop: "1px solid #ccc",
    backgroundColor: "#eee",
    padding: "0.5rem 1rem",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  button: {
    border: 0,
    backgroundColor: "#78f89f",
    borderRadius: 15,
    padding: "0.5rem 1rem",
    fontSize: "0.8rem",
    lineHeight: 2,
  },
});