import React, { Component, useState, useContext, useEffect, useRef } from 'react';
import { StyleSheet, Text, View, ScrollView, TextInput, Pressable, SafeAreaView, Button } from 'react-native';
import LabelRepRow from './LabelRepRow';
import Modal from './Modal'
import Video from './Video';
import DropdownComponent from './DropdownComponent';
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  getExerciseList, getSubjectSessions, getSessionDetails, getAllSubjectIdsAndNamesOnUnlabeledVideos,
  fetchUnlabeledList, getS3URL, addSegments, getSessionIdFromVideoUrl
} from '../services';
import {
  S3Client,
  CreateBucketCommand,
  DeleteBucketCommand,
  ListObjectsCommand
} from "@aws-sdk/client-s3";

import { CognitoIdentityClient, TooManyRequestsException } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import baseUrl from "../constants/constants"
import Navbar from "../components/Navbar"
import AWS from "aws-sdk";
import { AccountContext } from "../components/Account";
// import { SafeAreaView } from 'react-native-web';

const navTabs = [{ "name": "Login", "screen": "LoginScreen" }, { "name": "Unlabeled", "screen": "UnlabeledScreen" }]

const RepLabelingPage = ({ navigation }) => {


  const { authToken, getUserSession } = useContext(AccountContext);
  const [exerciseList, setExerciseList] = useState([]);
  const [userAuthToken, setUserAuthToken] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState(false);
  const [subjectArray, setSubjectArray] = useState([]);
  const [isRepStartButtonActive, setIsRepStartButtonActive] = useState(true)
  const [isLoading, setIsLoading] = useState(true);
  const [videoList, setVideoList] = useState([]);
  const [sessionId, setSessionId] = useState(null);
  const [newSessionId, setNewSessionId] = useState("");
  const [newSubjectId, setNewSubjectId] = useState("");
  const [radioBtnsData, setRadioBtnsData] = useState(['Good', 'Bad', 'Ugly']);
  const [selectedVal, setSelectedVal] = useState(null);
  const [vidURL, setVidURL] = useState("");
  const [exerciseValue, setExerciseValue] = useState(null);
  const [nameValue, setNameValue] = useState(null);
  const [idValue, setIdValue] = useState(null);
  const [exerciseSessions, setExerciseSessions] = useState([]);
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false)
  const [username, setUsername] = useState("");
  const [unsavedData, setUnsavedData] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [nextVideoUrl, setNextVideoUrl] = useState(null);
  const [shouldSetVideo, setShouldSetVideo] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [unsignedUrl, setUnsignedUrl] = useState(null)
  const labelRepRowRef = useRef(null);

  useEffect(() => {
    let exerciseArray = []
    console.log('authToken', authToken)
    getUserSession().then((session) => {
      console.log('user session called', session.accessToken.jwtToken)
      // getUnlabeledList(session.accessToken.jwtToken); 
      // get list of unlabeled videos and load the first one... 
      fetchUnlabeledList(session.accessToken.jwtToken, exerciseValue, idValue).then((sessionList) => {
        if (sessionList.length == 0) {
          setModalText("There are no new videos to label")
          setShowModal(true)
          return
        }
        setExerciseSessions(sessionList)

        setVideoList(sessionList)
        setSessionId(sessionList[0].session_id)
        setIsLoading(false)
        var urlSplit = sessionList[0].s3_url.split('.s3.amazonaws.com/');
        var bucketName = urlSplit[0].split('/')[2]

        console.log(bucketName, urlSplit)
        // + "-frame-overlayed"
        // var bucketName = urlSplit[0].split('/')[2] 
        // console.log(urlSplit)
        // console.log(bucketName)


        // API Call here... 
        getS3URL(session.accessToken.jwtToken, urlSplit[1], bucketName).then((response) => {

          const url = response.s3URL
          console.log(url)
          setVidURL(url)

        })

      }).catch(error => {
        toast(`API Call "Fetch Unlabeled List" Failed: Please report this issue!`,
          {
            render: `${error}`,
            type: 'error',
            autoClose: 5000,
          })
      })

      getAllSubjectIdsAndNamesOnUnlabeledVideos(session.accessToken.jwtToken).then((subjects) => {
        var subjectArr = []
        for (var subject of subjects) {
          subjectArr.push({
            label: `${subject.user_name} - ${subject.subject_id}`,
            value: subject.subject_id
          });
        }
        setSubjectArray(subjectArr);
        console.log('Fetched subjects:', subjects);
        console.log('subjectArr: ', subjectArr);

      }).catch(error => {
        toast(`API Call "Fetch Subjects" Failed: Please report this issue!`,
          {
            render: `${error}`,
            type: 'error',
            autoClose: 5000,
          })
      })

      // load exercise dropdown with exercise list
      getExerciseList(session.accessToken.jwtToken).then((exercises) => {

        for (var tKey in exercises) exerciseArray.push({ label: exercises[tKey]["exercise_name"], value: exercises[tKey]["exercise_key"] });
        setExerciseList(exerciseArray)
        console.log(exerciseArray)
        setUserAuthToken(session.accessToken.jwtToken)
        setIsLoading(false)
      }).catch(error => {
        toast(`API Call "Get Exercise List" Failed: Please report this issue!`,
          {
            render: `${error}`,
            type: 'error',
            autoClose: 5000,
          })
      })
    })

  }, [])

  useEffect(() => {
    console.log("subjectArray updated: ", subjectArray);
  }, [subjectArray]);
  useEffect(() => {
    console.log("unsavedData updated: ", unsavedData);
  }, [unsavedData]);

  useEffect(() => {
    console.log("nextVideoUrl updated: ", nextVideoUrl);
  }, [nextVideoUrl]);

  useEffect(() => {
    console.log("inside useEffect")
    console.log("unsavedData: ", unsavedData, " , nextVideoUrl: ", nextVideoUrl)
    if (!unsavedData && nextVideoUrl) {
      console.log("inside !unsavedData && nextVideoUrl useEffect if statement")
      console.log("is there unsaved data: ", unsavedData, " ,setting next video to video url: ", nextVideoUrl);
      setVid(nextVideoUrl);
      setNextVideoUrl(null);
    }
  }, [unsavedData, nextVideoUrl]);

  useEffect(() => {
    console.log("inside useEffect for shoudlSetVideo")
    if (shouldSetVideo) {
      console.log("shouldSetVideo == true, new video is being loaded in...")
      setVid(nextVideoUrl);
      setNextVideoUrl(null); // Clear nextVideoUrl after setting the video
      setShouldSetVideo(false); // Reset the trigger
    }
  }, [shouldSetVideo, nextVideoUrl]);


  // // ------------- Helper Functions ------------- 
  const getSubject = () => {
    console.log("getSubject function called")
    getSubjectSessions(userAuthToken, newSubjectId).then((sessions) => {
      console.log(sessions)
      setExerciseSessions(sessions)

    })
  }

  const getSession = () => {
    console.log("getSession function called")
    console.log(newSessionId)
    getSessionDetails(userAuthToken, newSessionId).then((session) => {
      console.log(session)
      setExerciseSessions(session)

    })
  }

  const logStartTime = () => {
    if (labelRepRowRef.current && isRepStartButtonActive) {
      setIsRepStartButtonActive(prevState => !prevState)
      labelRepRowRef.current.logStart(currentTime);
    }
  };

  const logStopTime = () => {
    if (labelRepRowRef.current && !isRepStartButtonActive) {
      setIsRepStartButtonActive(prevState => !prevState)
      labelRepRowRef.current.logStop(currentTime);
    }
  };

  async function markCurrentVideoAsJunk() {
    let labels = {
      repetitions: [{ id: 1, start: '-1', stop: '-1', label: '', comment: '' }]
    }
    submitSegments(labels);
  }


  function setVid(vidURL) {
    console.log("setVid function called on ", vidURL)
    setUnsignedUrl(vidURL)
    if (unsavedData) {
      console.log("there is unsaved data")
      setNextVideoUrl(vidURL);
      setShowWarningModal(true);
      return;
    }

    var urlSplit = vidURL.split('.s3.amazonaws.com/');
    var bucketName = urlSplit[0].split('/')[2];
    getSessionIdFromVideoUrl(authToken, vidURL).then((returnedSessionId) => {
      setSessionId(returnedSessionId);
      getSessionDetails(authToken, returnedSessionId).then((sessionDetails) => {
        console.log("sessionDetails: ", sessionDetails[0].subject_id);
      });
    });

    getS3URL(authToken, urlSplit[1], bucketName).then((response) => {
      const url = response.s3URL;
      setVidURL(url);
      console.log("setVid function called using URL: ", url);
    });
  }

  const closeModal = () => {
    console.log("showModal: ", showModal)
    setShowModal(!showModal)
  }


  const handleConfirm = () => {
    console.log("confirm button pressed, changing video...");
    console.log("nextVideoUrl: ", nextVideoUrl);
    setShowWarningModal(false);
    setUnsavedData(false);
    if (labelRepRowRef.current) {
      labelRepRowRef.current.resetTable();
    }
    if (nextVideoUrl) {
      setShouldSetVideo(true); // Trigger video setting after table reset
    }
  };


  const handleCancel = () => {
    console.log("cancel button pressed, no changes made");
    setShowWarningModal(false);
    setNextVideoUrl(null);
  };


  function submitSegments(labels) {
    console.log("submitSegments function called")
    console.log("labels: ", labels)
    getSessionDetails(authToken, sessionId).then((sessionDetails) => {
      console.log("sessionDetails: ", sessionDetails[0].subject_id)
      addSegments(authToken, sessionId, labels, sessionDetails[0].subject_id).then(() => {
        setIsLoading(true)
        getUnlabeledList();
      })
    })

  }

  // ------------- Rendering ------------- 

  if (showModal) {
    return (<Modal style={styles.popup} show={showModal} text={modalText} onClose={() => closeModal()}>
    </Modal>)
  }

  if (isLoading) {
    return (<View style={styles.container}>
      <Text>Loading...</Text>
    </View>)
  }

  return (
    <SafeAreaView >
      <Navbar navigation={navigation} username={null} />
      <ScrollView style={styles.scrollView}>


        <Modal
          show={showWarningModal}
          onClose={() => setShowWarningModal(false)}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          text="You have unsaved data. Are you sure you want to change the video?"
        />
        <Button title="mark this video as junk" onPress={markCurrentVideoAsJunk} />


      </ScrollView>

      <View style={styles.videoAndExerciseListContainer}>
        <ScrollView style={styles.videoContainer}>
          <Video source={vidURL} currentTime={currentTime} setCurrentTime={setCurrentTime} />
        </ScrollView>


        <ScrollView style={styles.exerciseList}>
          {exerciseSessions.map((session) => (
            <Pressable
              key={session.session_id}
              style={{ borderRadius: 5, backgroundColor: "lightgrey", margin: 10 }}
              onPress={() => setVid(session.s3_url)}
            >
              <Text style={{ color: "black" }}>Session ID: {session.session_id}</Text>
              <Text style={{ color: "black" }}>s3_url: {session.s3_url}</Text>
              <Text style={{ color: "black" }}>exercise type: {session.exercise_type}</Text>
              <Text style={{ color: "black" }}>date: {session.date}</Text>
              <Text style={{ color: "black" }}>Subject ID: {session.subject_id}</Text>
              <Text style={{ color: "black" }}>Subject name: {session.user_name}</Text>
            </Pressable>
          ))}
        </ScrollView>
      </View>








      <ScrollView style={styles.table}>
        <Button title="Log Rep Start" onPress={logStartTime} disabled={!isRepStartButtonActive} />
        <Button title="Log Rep Stop" onPress={logStopTime} disabled={isRepStartButtonActive} />
        <LabelRepRow ref={labelRepRowRef} submitLabels={submitSegments} loading={isLoading} setUnsavedData={setUnsavedData} setIsRepStartButtonActive={setIsRepStartButtonActive} />

        <View style={styles.answer}>

        </View>
      </ScrollView>





      <View style={{ marginTop: 20, minHeight: 500, backgroundColor: "lightgrey", flexDirection: "row" }}>
        <View style={styles.configColumn}>
          <Text>SessionID</Text>
          <TextInput
            style={{ backgroundColor: "white", borderRadius: 1 }}
            onChangeText={(e) => setNewSessionId(e)}
          ></TextInput>
          {(newSessionId) ? (<Pressable style={styles.getExercises} onPress={() => getSession()}>
            <Text style={{ color: "white" }}>Get Session</Text>
          </Pressable>) : (<></>)}
          <Text>SubjectID</Text>
          <TextInput
            style={{ backgroundColor: "white", borderRadius: 1 }}
            onChangeText={(e) => setNewSubjectId(e)}
          ></TextInput>
          {(newSubjectId) ? (<Pressable style={styles.getExercises} onPress={() => getSubject()}>
            <Text style={{ color: "white" }}>Get Sessions</Text>
          </Pressable>) : (<></>)}
        </View>
        <View style={[styles.configColumn, { zIndex: 3 }]}>

          <DropdownComponent
            options={exerciseList}
            type="Exercises"
            value={(value) => setExerciseValue(value)}
            containerStyle={{ zIndex: 10 }}
            zIndex={10}
          />

          <DropdownComponent
            options={subjectArray}
            type="Subjects"
            value={(value) => setIdValue(value)}
            containerStyle={{ zIndex: 1 }}
            zIndex={1}
          />


          {/* <DropdownComponent style={{minHeight: 500}}options={[{'value': 'power'},{'value': 'power'},{'value': 'power'},{'value': 'power'}]} type="Exercises" value={(value) => setExerciseValue(value)}></DropdownComponent> */}
          {(exerciseValue || idValue) ? (<Pressable style={styles.getExercises} onPress={() => getUnlabeledList()}>
            <Text style={{ color: "white" }}>Get Sessions</Text>
          </Pressable>) : (<></>)}
        </View>
      </View>
      <ToastContainer />



    </SafeAreaView>


  )

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexGrow: 1,
    margin: '0.2%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
  },
  videoContainer: {
    flex: 1,
    maxWidth: '50%',
    marginHorizontal: 20,
  },
  table: {
    flex: 5,
    maxHeight: '50%',
    minHeight: 300,
  },
  configColumn: {
    flex: 2,
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  selected: {
    flex: 1,
    flexDirection: 'column',
    fontSize: 16,
    margin: 5,
    padding: 10,
    backgroundColor: '#2196f3',
    color: 'white',
    borderRadius: 5,
    minWidth: 50,
    textAlign: 'center',
  },
  answer: {
    flexDirection: 'column',
    padding: 5,
  },
  dropdown: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  btn: {
    flex: 1,
    flexDirection: 'column',
    fontSize: 16,
    margin: 5,
    padding: 10,
    backgroundColor: '#EEEEEE',
    borderRadius: 5,
    minWidth: 50,
    textAlign: 'center',

  },
  navbar: {
    flexDirection: "row-reverse",
    flex: 1,
    minHeight: 50,
    height: 50,
    maxWidth: "100%",
    backgroundColor: '#3115A4'
  },
  getExercises: {
    backgroundColor: "blue",
    textAlign: "center",
    padding: 10,
    borderRadius: 5,
    margin: 10,
  },
  dropdown: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  exerciseList: {
    margin: 10,
    minHeight: 400,
    maxHeight: window.innerWidth * 0.325,
    maxWidth: window.innerWidth * 0.5,
    flex: 1
  },
  videoAndExerciseListContainer: {
    alignItems: 'stretch',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  popup: {
    zIndex: 1,
    justifyContent: "center"
  },
  scrollView: {
    flex: 1,
    marginHorizontal: 20,
    minWidth: '50%',
    maxWidth: "80%"
  },
  exercises: {
    minHeight: 15,
    flex: 1,
    position: 'relative',
    zIndex: 10
  },
  subjects: {
    position: 'relative',
    zIndex: 1
  }
});

export default RepLabelingPage