import React from 'react';
import { StyleSheet, SafeAreaView, Text, View, Pressable, Image, Dimensions, Animated } from 'react-native';
import Navbar from '../components/Navbar';

// Import images from assets folder
import jumpinggirl from '../../assets/jumpinggirl.jpeg';
import phoneskeletal from '../../assets/phoneskeletal.png';
import phonetasks from '../../assets/phonetasks.png';
import patientpt2 from '../../assets/patientpt2.jpeg';

const { width } = Dimensions.get('window');
const isSmallScreen = width < 600;

const LandingPage = ({ navigation }) => {
    const animatedValue = new Animated.Value(0);
    Animated.loop(
        Animated.timing(animatedValue, {
            toValue: 1,
            duration: 3000,
            useNativeDriver: false,
        })
    ).start();

    return (
        <SafeAreaView style={styles.container}>
            <Navbar navigation={navigation} username={null} />
            <View style={styles.diagonalContainer}>
                <View style={styles.contentRow}>
                    <View style={[styles.textContent, isSmallScreen ? styles.textContentSmall : null]}>
                        <Text style={isSmallScreen ? styles.titleSmall : styles.title}>Physical Therapy</Text>
                        <Text style={isSmallScreen ? styles.subtitleSmall : styles.subtitle}>On-Demand</Text>
                        <Text style={isSmallScreen ? styles.descriptionSmall : styles.description}>One sentence description</Text>
                    </View>
                    <Animated.View style={[styles.imageWrapper, { borderColor: 'white', width: isSmallScreen ? '50%' : '45%' }]}>
                        <Image source={jumpinggirl} style={styles.image} />
                    </Animated.View>
                </View>
            </View>
            <View style={styles.sectionContainer}>
                <View style={styles.sectionContent}>
                    <View style={[styles.sectionText, isSmallScreen ? styles.sectionTextSmall : null]}>
                        <Text style={isSmallScreen ? styles.titleSmall : styles.title}>Accessible + Accurate</Text>
                        <Text style={isSmallScreen ? styles.descriptionSmall : styles.description}>Description of motion capture technology</Text>
                    </View>
                    <View style={styles.phoneContainer}>
                        <View style={styles.phoneWrapper}>
                            <Image source={phoneskeletal} style={styles.phoneImage} />
                        </View>
                    </View>
                </View>
            </View>
            <View style={styles.sectionContainer}>
                <View style={styles.triangleContainer}>
                    <View style={styles.triangle}></View>
                    <View style={styles.rectangle}></View>
                    <View style={styles.imageContainer}>
                        <Image source={phonetasks} style={styles.patientImage} />
                    </View>
                </View>
                <View style={[styles.sectionContentWithPadding]}>
                    <View style={[styles.sectionText, isSmallScreen ? styles.sectionTextSmall : null]}>
                        <Text style={isSmallScreen ? styles.titleSmall : styles.title}>Personalized Care</Text>
                        <Text style={isSmallScreen ? styles.titleSmall : styles.title}>Anytime, Anywhere</Text>
                        <Text style={isSmallScreen ? styles.descriptionSmall : styles.description}>Describe convenience for PTs and patients</Text>
                    </View>
                </View>
            </View>
            <View style={styles.newSectionContainer}>
                <View style={styles.newSectionContent}>
                    <View style={styles.newSectionTextContainer}>
                        <Text style={isSmallScreen ? styles.titleSmall : styles.title}>Maximize time with your patients</Text>
                        <Text style={isSmallScreen ? styles.descriptionSmall : styles.description}>Describe patient connection and virtual assistant aspect</Text>
                        <Pressable style={styles.learnMoreButton}>
                            <Text style={styles.learnMoreButtonText}>Learn More</Text>
                        </Pressable>
                    </View>
                    <View style={styles.newSectionImageContainer}>
                        <Image source={patientpt2} style={styles.newSectionImage} />
                    </View>
                </View>
            </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f0f0f0',
    },
    diagonalContainer: {
        width: '100%',
        backgroundColor: '#f0f0f0',
        transform: [{ rotate: '-5deg' }],
        marginTop: -10,
        paddingVertical: 60,
        alignItems: 'center',
        overflow: 'hidden',
    },
    contentRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '90%',
        transform: [{ rotate: '5deg' }],
    },
    textContent: {
        flex: 1,
        paddingRight: 10,
    },
    textContentSmall: {
        flex: 1,
        paddingRight: 5,
    },
    imageWrapper: {
        aspectRatio: 4 / 3,
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
        padding: 10,
        borderWidth: 2,
        borderColor: 'white',
        overflow: 'hidden',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.1,
        shadowRadius: 8,
    },
    image: {
        width: '100%',
        height: '100%',
        resizeMode: 'contain',
        borderRadius: 10,
    },
    title: {
        fontSize: 60,
        fontWeight: 'bold',
        textAlign: 'left',
        color: 'black',
        textShadowColor: 'rgba(0, 0, 0, 0.75)',
        textShadowOffset: { width: -1, height: 1 },
        textShadowRadius: 10,
    },
    subtitle: {
        fontSize: 60,
        fontWeight: 'bold',
        textAlign: 'left',
        color: 'black',
        textShadowColor: 'rgba(0, 0, 0, 0.75)',
        textShadowOffset: { width: -1, height: 1 },
        textShadowRadius: 10,
    },
    description: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#666',
        textAlign: 'left',
    },
    titleSmall: {
        fontSize: 30,
        fontWeight: 'bold',
        textAlign: 'left',
        color: 'black',
        textShadowColor: 'rgba(0, 0, 0, 0.75)',
        textShadowOffset: { width: -1, height: 1 },
        textShadowRadius: 10,
    },
    subtitleSmall: {
        fontSize: 30,
        fontWeight: 'bold',
        textAlign: 'left',
        color: 'black',
        textShadowColor: 'rgba(0, 0, 0, 0.75)',
        textShadowOffset: { width: -1, height: 1 },
        textShadowRadius: 10,
    },
    descriptionSmall: {
        fontSize: 15,
        fontWeight: 'bold',
        color: '#666',
        textAlign: 'left',
    },
    sectionContainer: {
        width: '100%',
        paddingVertical: 50,
        alignItems: 'center',
        marginTop: 40,
        position: 'relative',
        overflow: 'hidden',
    },
    sectionContent: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '90%',
        paddingHorizontal: 20,
    },
    sectionText: {
        flex: 1,
        paddingRight: 10,
    },
    sectionTextSmall: {
        flex: 1,
        paddingRight: 5,
    },
    phoneContainer: {
        flex: 1,
        alignItems: 'flex-end',
    },
    phoneWrapper: {
        width: '80%',
        aspectRatio: 1,
        backgroundColor: '#f0f0f0',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
        padding: 10,
        transform: [{ rotate: '10deg' }],
        borderWidth: 2,
        borderColor: '#d3d3d3',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.1,
        shadowRadius: 8,
    },
    phoneImage: {
        width: '100%',
        height: '100%',
        resizeMode: 'contain',
        borderRadius: 10,
    },
    button: {
        marginTop: 20,
        padding: 15,
        backgroundColor: '#007bff',
        borderRadius: 10,
    },
    buttonText: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    triangleContainer: {
        width: '100%',
        height: 700,
        position: 'relative',
    },
    triangle: {
        width: 0,
        height: 0,
        borderRightWidth: width * 2,
        borderBottomWidth: 150,
        borderRightColor: 'transparent',
        borderBottomColor: '#f0f0f0',
        position: 'absolute',
        left: 0,
        top: 0,
    },
    rectangle: {
        width: '30%',
        height: '80%',
        backgroundColor: '#f0f0f0',
        position: 'absolute',
        left: 0,
        top: '20%',
    },
    imageContainer: {
        position: 'absolute',
        top: '30%',
        left: '40%',
        width: '45%',
        aspectRatio: 4 / 3,
        backgroundColor: '#f0f0f0',
        borderRadius: 10,
        overflow: 'hidden',
        borderWidth: 2,
        borderColor: 'gray',
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.1,
        shadowRadius: 8,
    },
    patientImage: {
        width: '100%',
        height: '100%',
        resizeMode: 'cover',
    },
    sectionContentWithPadding: {
        position: 'absolute',
        top: '10%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '90%',
        paddingHorizontal: 20,
    },
    newSectionContainer: {
        width: '100%',
        paddingVertical: 50,
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: '#f0f0f0',
    },
    newSectionContent: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '90%',
        paddingHorizontal: 20,
    },
    newSectionTextContainer: {
        flex: 1,
    },
    newSectionTitle: {
        fontSize: 60,
        fontWeight: 'bold',
        textAlign: 'left',
        color: 'black',
        textShadowColor: 'rgba(0, 0, 0, 0.75)',
        textShadowOffset: { width: -1, height: 1 },
        textShadowRadius: 10,
        marginBottom: 10,
    },
    newSectionDescription: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#666',
        textAlign: 'left',
        textShadowColor: 'rgba(0, 0, 0, 0.75)',
        textShadowOffset: { width: -1, height: 1 },
        textShadowRadius: 10,
        marginBottom: 20,
    },
    learnMoreButton: {
        paddingVertical: 8,
        paddingHorizontal: 16,
        backgroundColor: '#007bff',
        borderRadius: 20,
        top: 20,
        maxWidth: 200,
        alignSelf: 'flex-start',
    },
    learnMoreButtonText: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    newSectionImageContainer: {
        width: '50%',
        aspectRatio: 1,
        backgroundColor: '#f0f0f0',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
        borderWidth: 2,
        borderColor: 'gray',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.1,
        shadowRadius: 8,
    },
    newSectionImage: {
        width: '100%',
        height: '100%',
        resizeMode: 'cover',
        borderRadius: 10,
    },
});

export default LandingPage;
