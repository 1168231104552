import { StyleSheet, Text, TextInput, Keyboard, TouchableWithoutFeedback, AppState } from "react-native";
import React, { useContext, useState, useEffect } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import RepLabelingPage from "../components/RepLabelingPage";
import LoginScreen from "../components/LoginScreen";
import Navbar from "../components/Navbar";
import { AccountContext } from "../components/Account";
import LandingPage from "../components/LandingPage";
import VideoUploadPage from "../components/VideoUploadPage";

const Stack = createNativeStackNavigator();

const AppNav = () => {
  const [userAuthToken, setUserAuthToken] = useState(null);
  const { isAuth, getUserSession } = useContext(AccountContext);
  console.log('isAuth?', isAuth);

  useEffect(() => {
    getUserSession().then((session) => {
      console.log('session:', session.accessToken.jwtToken);
      setUserAuthToken(session.accessToken.jwtToken);
    });
  }, []);

  return (
    <NavigationContainer>
      {/* {userAuthToken ? (
        <LabelerStack />
      ) : ( */}
        <VisitorStack />
      {/* )} */}
    </NavigationContainer>
  );
};

const VisitorStack = () => {
  console.log("Loading VisitorStack");
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
      }}
      initialRouteName={"LandingPage"}
    >
      <Stack.Screen name={"LandingPage"} component={LandingPage} />
      <Stack.Screen name={"LoginScreen"} component={LoginScreen} />
      <Stack.Screen name={"RepLabelingPage"} component={RepLabelingPage} />
      <Stack.Screen name={"VideoUploadPage"} component={VideoUploadPage} />
    </Stack.Navigator>
  );
};

// const LabelerStack = () => {
//   console.log("Loading LabelerStack");
//   return (
//     <Stack.Navigator
//       screenOptions={{
//         headerShown: true,
//       }}
//       initialRouteName={"Home"}
//     >
//       <Stack.Screen
//         name={"Home"}
//         component={Home}
//       />
//       <Stack.Screen name={"LoginScreen"} component={LoginScreen} />
//       <Stack.Screen name={"Navbar"} component={Navbar} />
//       <Stack.Screen name={"RepLabel"} component={RepLabel} />
//       <Stack.Screen name={"VideoUpload"} component={VideoUpload} />
//     </Stack.Navigator>
//   );
// };

export default AppNav;
