import React, { createContext, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UploadContext = createContext();

const UploadProvider = ({ children }) => {
  const [uploadQueue, setUploadQueue] = useState([]);

  const addUploadTask = (task) => {
    setUploadQueue((prevQueue) => [...prevQueue, task]);
  };

  const removeUploadTask = (taskId) => {
    setUploadQueue((prevQueue) => prevQueue.filter(task => task.id !== taskId));
  };

  const updateUploadTask = (taskId, progress) => {
    setUploadQueue((prevQueue) =>
      prevQueue.map(task => 
        task.id === taskId ? { ...task, progress } : task
      )
    );
  };

  return (
    <UploadContext.Provider value={{ uploadQueue, addUploadTask, removeUploadTask, updateUploadTask }}>
      {children}
    </UploadContext.Provider>
  );
};

export { UploadContext, UploadProvider };