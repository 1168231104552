import { StyleSheet, Text, TextInput, Keyboard, TouchableWithoutFeedback, AppState } from "react-native";
import React, {useContext} from "react";
import AppNav from "./navigation/AppNav"
import {Account} from "./components/Account"


const Main = () => {


    const DismissKeyboard = ({ children }) => (
        <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
          {children}
        </TouchableWithoutFeedback>
      );

    return(

        <DismissKeyboard>
            <Account>
                <AppNav/>
            </Account>
        </DismissKeyboard>
    )
}

export default Main;