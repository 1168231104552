import React, { useState, useEffect, useRef } from "react";
import { View, StyleSheet, TextInput, Picker } from 'react-native';

const LabelRepInput = ({ count, onUpdate, style, currentData }) => {
  const [start, setStart] = useState(currentData.start || "");
  const [stop, setStop] = useState(currentData.stop || "");
  const [selectedLabel, setSelectedLabel] = useState(currentData.label || "");
  const [comment, setComment] = useState(currentData.comment || "");
  const hasUpdated = useRef(false);

  useEffect(() => {
    setStart(currentData.start || "");
    setStop(currentData.stop || "");
    setSelectedLabel(currentData.label || "");
    setComment(currentData.comment || "");
  }, [currentData]);

  useEffect(() => {
    if (hasUpdated.current) {
      const repetition = {
        id: count,
        start: start,
        stop: stop,
        label: selectedLabel,
        comment: comment,
        inactive: true
      };
  
      onUpdate(repetition);
    } else {
      hasUpdated.current = true;
    }
  }, [start, stop, selectedLabel, comment]);

  const setNumber = (numType, num) => {
    if (!num.match('^[0-9]*$')) {
      alert('Please enter a valid number');
      return;
    }

    if (numType === "start") {
      setStart(num);
    } else if (numType === "stop") {
      setStop(num);
    }
  };

  

  return (
    <View>
      <View style={{ flexDirection: 'row' }}>
        <View style={style}>
          {count}
        </View>
        <TextInput
          multiline
          style={style}
          value={start}
          onChangeText={(text) => setNumber("start", text)}
        />
        <TextInput
          multiline
          style={style}
          value={stop}
          onChangeText={(text) => setNumber("stop", text)}
        />
        <Picker
          style={style}
          selectedValue={selectedLabel}
          onValueChange={(itemValue) => setSelectedLabel(itemValue)}
        >
          <Picker.Item label="Select Label" value="" />
          <Picker.Item label="Good" value="good" />
          <Picker.Item label="Bad" value="bad" />
          <Picker.Item label="Ugly" value="ugly" />
        </Picker>
        <TextInput
          multiline
          style={[style, { flex: 1, height: 'auto' }]}
          value={comment}
          onChangeText={(text) => setComment(text)}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 6,
    flexGrow: 1,
    padding: 10,
    marginRight: 10,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    display: 'table',
  },
  head: {
    display: 'table-cell',
    textAlign: 'center',
    border: '1px solid black',
    borderRightWidth: 0,
    height: 'auto',
    width: '20%'
  },
});

export default LabelRepInput;