import { StyleSheet, View, Button, Text, TextInput, Pressable } from 'react-native';
import { useState, useContext } from "react";
import React from "react";
import { AccountContext } from "./Account";
import style from './style';
import { useNavigation } from '@react-navigation/native';

const Login = (props, {navigation}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { authenticate } = useContext(AccountContext);
    // const navigation = useNavigation(); // Hook for navigation

    const onSubmit = (e) => {
        e.preventDefault();
        authenticate(email, password)
            .then((data) => {
                getUser(data.accessToken.jwtToken, data.accessToken.payload.sub).then((result) => {
                    props.login(true, data.accessToken, result[0].user_id, email); // Pass email as username
                    navigation.navigate("Home"); // Navigate to Home page
                })
            })
            .catch((err) => {
                console.error("Failed to login", err);
            })
    };

    return (
        <View style={{ alignItems: "center", backgroundColor: '#fafafa', padding: 20 }}>
            <View onSubmit={onSubmit} style={styles.container}>
                <Text style={style.h3}>Email</Text>
                <TextInput
                    style={style.textinput}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} />
                <Text style={style.h3}>Password</Text>
                <TextInput
                    style={style.textinput}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} />
            </View>
            <Pressable style={style.loginButton} onPress={onSubmit}>
                <Text style={style.buttonText}>Login</Text>
            </Pressable>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexGrow: 1,
        padding: 10,
        margin: 10,
        width: '100%',
        marginTop: "10%",
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
    }
})

async function getUser(authToken, cognitoSub) {
    return fetch("https://dev-api.crowdpt.com/" + "user/type/" + cognitoSub, {
        method: "GET",
        withCredentials: true,
        headers: {
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': 'https://dev-api.crowdpt.com/*',
            "Content-Type": "application/json",
            'Accept': "*/*",
            "authorization": "Bearer " + authToken
        }
    }).then((response) => response.json()).then((responseJson) => {
        return responseJson
    }).catch(function (error) {
        console.log('There has been a problem with your fetch operation: ' + error.message);
        throw error;
    });
}

export default Login
