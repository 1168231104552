import React, { Component } from 'react';
import baseUrl from "./constants/constants"


export async function getExerciseList(authToken) {

    console.log('calling get Exercise list with token', authToken)
    return fetch(baseUrl + "session/exercises", {
        method: 'GET',
        withCredentials: true,
        headers: {
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': '*',
            "Content-Type": "application/json",
            'Accept': "*/*",
            authorization: "Bearer " + authToken,
        }
    }).then(response => {
        if (response.ok) {
            return response.json();
        }
    }).catch(error => {

        console.error("Error fetching data:", error);
        return error
        // throw error;
    });
}

//gets all subject_names from videos that are unlabeled

export async function getAllSubjectIdsAndNamesOnUnlabeledVideos(authToken) {
    return fetch(baseUrl + "segments/unlabeled/allusers", {
        method: 'GET',
        withCredentials: true,
        headers: {
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': '*',
            "Content-Type": "application/json",
            'Accept': "*/*",
            authorization: "Bearer " + authToken,
        }
    }).then(response => {
        if (response.ok) {
            return response.json();
        }
    }).catch(error => {

        console.error("Error fetching data:", error);
        return error
        // throw error;
    });
}
//gets all unlabeled exercise videos with possibility of filtering

//returns the sessionId associated with a particular videoUrl
export async function getSessionIdFromVideoUrl(authToken, videoUrl) {
    ``
    var urlModifiers = "?s3_url=" + videoUrl;
    console.log("final url: ", baseUrl + "segments/unlabeled" + urlModifiers)
    return fetch(baseUrl + "segments/unlabeled" + urlModifiers, {
        method: 'GET',
        withCredentials: true,
        headers: {
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Origin': 'http://localhost:8080/*',
            'Access-Control-Allow-Origin': 'https://dev-api.crowdpt.com/*',
            "Content-Type": "application/json",
            'Accept': "*/*",
            authorization: "Bearer " + authToken,
        }
    }).then((response) => response.json())
        .then(response => {
            if (response) {
                console.log("getSessionIdFromVideoUrl response: ", response);
                console.log("the session_id corresponding to ", videoUrl, " is: ", response[0].session_id);
                return response[0].session_id;
            }
        }).catch(error => {

            console.error("Error fetching data:", error);
            return error
            // throw error;
        });

}

export async function getSessionDetails(authToken, sessionId) {

    console.log(sessionId)
    return fetch(baseUrl + "session/" + sessionId, {
        method: 'GET',
        withCredentials: true,
        headers: {
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Origin': 'http://localhost:8080/*',
            'Access-Control-Allow-Origin': 'https://dev-api.crowdpt.com/*',
            "Content-Type": "application/json",
            'Accept': "*/*",
            authorization: "Bearer " + authToken,
        }
    }).then(response => {
        if (response.ok) {
            return response.json();
        }
    }).catch(error => {

        console.error("Error fetching data:", error);
        return error
        // throw error;
    });
}

export async function getSubjectSessions(authToken, subjectId) {

    console.log(subjectId)

    let reqBody = {
        'patient_id': subjectId
    }

    return fetch(baseUrl + "session/patient", {
        method: 'POST',
        withCredentials: true,
        headers: {
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Origin': 'http://localhost:8080/*',
            'Access-Control-Allow-Origin': 'https://dev-api.crowdpt.com/*',
            "Content-Type": "application/json",
            'Accept': "*/*",
            authorization: "Bearer " + authToken,

        },
        body: JSON.stringify(reqBody)
    }).then(response => {
        if (response.ok) {
            return response.json();
        }
    }).catch(error => {

        console.error("Error fetching data:", error);
        return error
        // throw error;
    });
}

export async function fetchUnlabeledList(authToken, exerciseKey, idValue) {

    var urlModifiers = "?";
    var urlArr = []
    if (exerciseKey !== null) urlArr.push(`exercise_type=${exerciseKey}`);
    if (idValue !== null) urlArr.push(`subject_id=${idValue}`);
    urlModifiers += urlArr.join('&');
    console.log("final url: ", baseUrl + "segments/unlabeled" + urlModifiers)

    return fetch(baseUrl + "segments/unlabeled" + urlModifiers, {
        method: 'GET',
        withCredentials: true,
        headers: {
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Origin': 'http://localhost:8080/*',
            "Content-Type": "application/json",
            'Accept': "*/*",
            authorization: "Bearer " + authToken,
        }
    })
    .then(response => {
        if (response.ok) {
            return response.json();
        }
    }).catch(error => {

        console.error("Error fetching data:", error);
        return error
        // throw error;
    });
}

export async function addSegments(authToken, sessionIdOfVideo, labels, userId) {

    console.log('labels.repetitions: ', labels.repetitions)
    console.log(`addSegments function called: addSegments(${authToken}, ${sessionIdOfVideo}, ${labels}, ${userId})`)

    let reqBody = {
        "session_id": sessionIdOfVideo,
        "user_id": userId,
        "segments": labels.repetitions
    }

    console.log("addSegment body", reqBody)


    return fetch(baseUrl + 'segments/add', {
        method: 'POST',
        // mode: 'cors',
        headers: {
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': 'https://api.crowdpt.com/*',
            'Access-Control-Allow-Origin': 'https://localhost:8080/*',
            "Content-Type": "application/json",
            'Accept': "*/*",
            "authorization": "Bearer " + authToken
        },
        body: JSON.stringify(reqBody),
        withCredentials: true
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            else {
                var error = new Error('Error ' + response.status + ': ' + response.statusText)
                throw error;
            }
        },
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            })
}

export async function getS3URL(authToken, vidName, bucketName) {


    let reqBody = {
        "vidName": vidName,
        "bucketName": bucketName
    }

    // console.log("addSegment body", reqBody)


    return fetch(baseUrl + 'aws/s3ObjectLink', {
        method: 'POST',
        withCredentials: true,
        // mode: 'cors', 
        headers: {
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': 'https://api.crowdpt.com/*',
            "Content-Type": "application/json",
            'Accept': "*/*",
            "authorization": "Bearer " + authToken
        },
        body: JSON.stringify(reqBody)

    })
        .then(response => {
            console.log('response', response)
            if (response.ok) {
                return response.json();
            }
            else {
                var error = new Error('Error ' + response.status + ': ' + response.statusText)
                // error.response = response;
                throw error;
            }
        },
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            })
}