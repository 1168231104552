import React, { createContext, useState, useEffect, useContext } from 'react';
import { getExerciseList, getAllSubjectIdsAndNamesOnUnlabeledVideos } from '../services';
import {Account, AccountContext} from "../components/Account";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const { authToken, getUserSession } = useContext(AccountContext);
  const [exerciseList, setExerciseList] = useState([]);
  const [subjectArray, setSubjectArray] = useState([]);

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const subjects = await getAllSubjectIdsAndNamesOnUnlabeledVideos(authToken);
        const subjectArr = subjects.map(subject => ({
          label: `${subject.user_name} - ${subject.subject_id}`,
          value: subject.subject_id,
        }));
        setSubjectArray(subjectArr);
        console.log('Fetched subjects:', subjects);
        console.log('subjectArr: ', subjectArr);
      } catch (error) {
        console.error('Error fetching subjects:', error);
      }
    };

    fetchSubjects();

    const fetchExercises = async () => {
      try {
        const exercises = await getExerciseList(authToken);
        const exerciseArray = exercises.map(exercise => ({
          label: exercise.exercise_name,
          value: exercise.exercise_key,
        }));
        setExerciseList(exerciseArray);
        console.log("exerciseArray: ", exerciseArray);
      } catch (error) {
        console.error('Error fetching exercises:', error);
      }
    };

    fetchExercises();
  }, [authToken, getUserSession]);

  return (
    <DataContext.Provider value={{ exerciseList, subjectArray }}>
      {children}
    </DataContext.Provider>
  );
};