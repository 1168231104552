import React, { createContext, useState } from "react";
/**
 * @file Account.js
 * @description This file defines the Account component which provides authentication context and functionality using Amazon Cognito.
 */


import {CognitoUser, AuthenticationDetails} from "amazon-cognito-identity-js";
import {View} from "react-native"; 
import UserPool from "./UserPool";


/**
 * @constant AccountContext
 * @description Creates a React Context for account-related state and actions.
 */
const AccountContext = createContext({
    authenticate: () => { },
    getUserSession: () => { },
    isAuth: false,
    authToken: null,
    userToken: null,
    setUserToken: () => { },
    username: null, // Added username
    setUsername: () => { } // Added setUsername
});

/**
 * @function Account
 * @description Functional component that provides authentication context and functionality.
 * @param {Object} props - The props passed to the component.
 * @returns {JSX.Element} The rendered component.
 */
const Account = (props) => {
    const [isAuth, setAuth] = useState(false);
    const [authToken, setAuthToken] = useState("");
    const [userToken, setUserToken] = useState(undefined);
    const [username, setUsername] = useState(""); // Added username state

    const getUserSession = async () => {
        return await new Promise((resolve, reject) => {
            const user = UserPool.getCurrentUser();
            console.log('user',user.username)
            setUsername(user.username)
            if (user) {
                user.getSession((err, session) => {
                    if (err) {
                        reject();
                    }
                    else {
                        resolve(session);
                    }
                });
            }
            else {
                reject();
            }
        })
    }



     /**
     * @function authenticate
     * @description Authenticates a user with the provided username and password.
     * @param {string} Username - The username of the user.
     * @param {string} Password - The password of the user.
     * @returns {Promise} Resolves with authentication data if successful, otherwise rejects.
     */
    const authenticate = async (Username, Password) => {
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({
                Username,
                Pool: UserPool,
            });

            const authDetails = new AuthenticationDetails({ Username, Password });

            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    setAuthToken(data.accessToken.jwtToken)
                    setAuth(true)
                    setUsername(Username); // Set username
                    resolve(data);
                },
                onFailure: (err) => {
                    console.log("onFailure: ", err);
                    reject(err);
                },
                newPasswordRequired: function (userAttributes, requiredAttributes) {
                    delete userAttributes.email_verified;
                    delete userAttributes.email;
                    user.completeNewPasswordChallenge("Alex1234!", userAttributes, {
                        onSuccess: (data) => {
                            setAuthToken(data.accessToken.jwtToken)
                            setAuth(true)
                            setUsername(Username); // Set username
                            resolve(data);
                        },
                        onFailure: (err) => {
                            reject(err);
                        }
                    })
                }
            })
        })
    }


    /**
     * @function changePassword
     * @description Changes the password for the given user during the new password challenge.
     * @param {string} Username - The username of the user.
     * @param {Object} userAttributes - The user attributes required for the password change.
     * @param {string} newPassword - The new password for the user.
     * @returns {void}
     */

    const changePassword = async (Username,userAttributes,newPassword) => {
        // var userAttributes
        // return await new Promise((resolve, reject) => {
            console.log("before")
            const user = new CognitoUser({
                Username,
                Pool: UserPool,
            });
            console.log("after")
    
            // getSession()
            // const authDetails = new AuthenticationDetails({Username, newPassword});
    
            // user.getUserAttributes(function(err, attributes) {
            //     if (err) {
            //         console.log(err)
            //     } else {
            //         console.log('attributes', attributes)
            //         userAttributes = attributes
            //     }
            user.completeNewPasswordChallenge(newPassword, userAttributes,{
                onSuccess: (data) => {
                    console.log("onSuccess: ", data);
                    // resolve(data);
                }, 
                onFailure: (err) => {
                    console.log("onFailure: ", err);
                    // reject(err);
                },
            })
               
            // })
        // })


      };

    return(
        
        <AccountContext.Provider value={{authenticate, getUserSession, isAuth,authToken,userToken,
            setUserToken}}>
            {props.children}
        </AccountContext.Provider>
    );
}

export { Account, AccountContext };
