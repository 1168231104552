import React, {useContext,useEffect,useState} from 'react'
import { StyleSheet, Text, View, Pressable } from 'react-native'
import { AccountContext } from "./Account"

const Navbar = ({ navigation }) => {
  const { authToken,username, getUserSession } = useContext(AccountContext)
  const [userAuthToken, setUserAuthToken] = useState(null)

  useEffect(() => {
    console.log('username', username)
    getUserSession().then((session) => {
      setUserAuthToken(session.accessToken.jwtToken)
    })
  }, [])

  return (
    <View style={styles.navbar}>
      <View style={styles.navButtonsContainer}>
      <Pressable 
          style={styles.navButton}
          onPress={() => navigation.navigate("LandingPage")}>
          <Text style={styles.navButtonText}>Home</Text>
        </Pressable>
        <Pressable 
          style={styles.navButton}
          onPress={() => navigation.navigate("RepLabelingPage")}>
          <Text style={styles.navButtonText}>Rep Labeling Page</Text>
        </Pressable>
        <Pressable
          style={styles.navButton}
          onPress={() => navigation.navigate("VideoUploadPage")}>
          <Text style={styles.navButtonText}>Video Upload Page</Text>
        </Pressable>
      </View>
      <View style={styles.loginContainer}>
        {userAuthToken ? (
          // <Text style={styles.navButtonText}>Welcome {username}</Text>
          <Pressable
          style={styles.navButton}
          onPress={() => navigation.navigate("LoginScreen")}>
          <Text style={styles.navButtonText}>Welcome {username}</Text>
        </Pressable>
        ) : (
          <Pressable
            style={styles.navButton}
            onPress={() => navigation.navigate("LoginScreen")}>
            <Text style={styles.navButtonText}>Login</Text>
          </Pressable>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  navbar: {
    flexDirection: "row",
    height: 50,
    width: "100%",
    backgroundColor: "white",
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    zIndex: 3,
  },
  navButtonsContainer: {
    flexDirection: "row",
  },
  navButton: {
    backgroundColor: "black",
    borderRadius: 5,
    padding: 10,
    marginRight: 10,
  },
  navButtonText: {
    color: "white",
  },
  loginContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
});

export default Navbar;
