import {CognitoUserPool} from "amazon-cognito-identity-js";
/*
sets up cognito user pool to manage logins, signups, passwords, etc
*/
const poolData = {
    // UserPoolId: "us-east-1_btMyaRERg",
    // ClientId: "17570lgbof4es6ra2qevkh1sqk"
    UserPoolId: "us-east-1_sAgwEkvn1",
    ClientId: "4th5evk1gta7dcmpm42b9sqahs"
  }

export default new CognitoUserPool(poolData);