import { StatusBar } from 'expo-status-bar';
import React, {Component} from 'react';
import { StyleSheet, Text, View,TextInput, Button,TouchableOpacity  } from 'react-native';
import Video from './src/components/Video';
import Dropdown from './src/components/Dropdown';
import Authentication from './src/components/Authentication';
import LabelRepRow from './src/components/LabelRepRow';
import SignUp from './src/components/SignUp';
import Login from './src/components/Login';
import {Account} from './src/components/Account';
import RepLabelingPage from './src/components/RepLabelingPage';
import style from './src/components/style';
import Main from "./src/Main";
import { DataProvider } from './src/contexts/DataContext';
import { UploadProvider } from './src/contexts/UploadContext';

// import { Amplify } from 'aws-amplify';

// import { withAuthenticator } from '@aws-amplify/ui-react';
// import '@aws-amplify/ui-react/styles.css';

// import awsExports from './aws-exports';
// Amplify.configure(awsExports);

export default class App extends Component {

  
  constructor(props) {
    super(props);
    this.state = {
        radioBtnsData: ['Good', 'Bad', 'Ugly'],
        checked: 0,
        selectedVal: null,
        feedback: null, 
        authenticated: false,
        authToken: null,
        userId: null
    }

    this.changeStatus = this.changeStatus.bind(this); 
  }

  onPress = (data) => {
    this.setState({selectedVal: data.data})
    console.log(data)
    console.log(this.state.selectedVal)
  }

  onVarSelect = () => {
    this.setState(state => ({ feedback: state }));
  };

  async componentDidMount() {
  

    // const response =  await fetchData();  
    // console.log(response)
    
    // const response2 =  await fetchVideos();  
    // console.log(response2)

  }
  
  changeStatus(auth, authToken, newUserId){
    let newToken = authToken.jwtToken
    this.setState({authToken:newToken, authenticated:auth,userId:newUserId})
  }

  
  
  render(){
        return (
          <UploadProvider>
            {/* <DataProvider> */}
              <View style={styles.container} >
                <Main/>                            
              </View>
            {/* </DataProvider> */}
          </UploadProvider>
          

            
        )
    
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexGrow: 1,
    margin: '0.2%',
    flexDirection:'row',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
  },
  table: {
    flex: 5,
  },
  selected:{
    flex:1,
    flexDirection: 'column',
    fontSize: 16,
    margin: 5,
    padding: 10,
    backgroundColor:'#2196f3',
    color:'white',
    borderRadius: 5,
    minWidth: 50,
    textAlign: 'center',
  },
  answer:{
    flexDirection: 'column',
    padding:5,
  },
  dropdown:{
    flexGrow: 1,
    justifyContent:'center',
    alignItems: 'center',
    height: '100%',
  },
  btn:{
    flex:1,
    flexDirection: 'column',
    fontSize: 16,
    margin: 5,
    padding: 10,
    backgroundColor:'#EEEEEE',
    borderRadius: 5,
    minWidth: 50,
    textAlign: 'center',

}
});

// 



async function fetchData() {


  return fetch('https://staging-api.crowdpt.com/' + 'status/tasks',{
    method: 'GET',
    // withCredentials: true,
    mode: 'cors',
    headers: {
      'Access-Control-Allow-Origin': 'https://staging-api.crowdpt.com/*',
      "Content-Type": "application/json",
      'Access-Control-Allow-Headers': '*',
      'Accept': "*/*"
    }
  })
  .then(response => {
          console.log('response', response)
          if (response.ok) {
              return response.json();
          }
          else {
              var error = new Error('Error ' + response.status + ': ' + response.statusText)
              // error.response = response;
              throw error;
          }
      },
      error => {
          var errmess = new Error(error.message);
          throw errmess;
  })
}
