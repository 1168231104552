import { createElement } from "react-native-web";
import React from 'react';

/*
renders a video player component on the webpage
uses props.source as the URL for video
*/

const Video = ({ source, currentTime, setCurrentTime }) => {
  const handleTimeUpdate = (e) => {
    setCurrentTime(Math.round(e.target.currentTime * 1000));
  };

  const attrs = {
    src: source,
    style: {
      width: '100%',
      height: '100%',
      maxHeight: 0.7 * window.innerHeight + 'px',
      maxWidth: 0.5 * window.innerWidth + 'px',
      margin: 'auto',
      padding: 5,
    },
    controls: "controls",
    onTimeUpdate: handleTimeUpdate
  };

  return React.createElement(
    'div',
    null,
    React.createElement('video', attrs),
    React.createElement(
      'div',
      { style: { marginTop: 10, fontSize: '18px' } },
      `Current Time: ${currentTime} ms`
    )
  );
}

export default Video;