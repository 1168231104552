import React, { useState } from 'react';
import { StyleSheet, View, Text, TouchableOpacity, FlatList } from 'react-native';
import * as DocumentPicker from 'expo-document-picker';
import PropTypes from 'prop-types';

const FileUploadComponent = ({ onFilesChange }) => {
  const [currentFiles, setCurrentFiles] = useState([]);

  const handleFileSelect = async () => {
    console.log("handleFileSelect called");
    try {
      const result = await DocumentPicker.getDocumentAsync({
        type: 'video/*',
        multiple: true,
      });
      console.log("result: ", result);
      console.log("resultType: ", result.type)
  
      if (result.canceled) {
        console.log("User cancelled the file picker");
        return;
      }
  
      if (result.assets) {
        const files = result.assets.map(file => ({ name: file.name, uri: file.uri }));
        const updatedFiles = [...currentFiles, ...files];
        console.log("updatedFiles: ", updatedFiles);
        setCurrentFiles(updatedFiles);
        if (onFilesChange) onFilesChange(updatedFiles);
      } else if (result.output) {
        const files = result.output.map(file => ({ name: file.name, uri: file.uri }));
        const updatedFiles = [...currentFiles, ...files];
        console.log("updatedFiles: ", updatedFiles);
        setCurrentFiles(updatedFiles);
        if (onFilesChange) onFilesChange(updatedFiles);
      } else {
        console.log("No files were selected");
      }
    } catch (err) {
      console.log('Error picking document:', err);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.dragAndDropArea}>
        <Text>Drag and drop functionality is not supported in React Native.</Text>
      </View>
      <TouchableOpacity onPress={handleFileSelect} style={styles.fileLabel}>
        <Text>Click to upload video files</Text>
      </TouchableOpacity>
      <View style={styles.uploadedFilesContainer}>
        <Text style={styles.heading}>Uploaded Files:</Text>
        <FlatList
          data={currentFiles}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => <Text style={styles.fileListItem}>{item.name}</Text>}
        />
      </View>
    </View>
  );
};

FileUploadComponent.propTypes = {
  onFilesChange: PropTypes.func,
};

const styles = StyleSheet.create({
  container: {
    margin: 20,
  },
  dragAndDropArea: {
    border: '2px dashed #cccccc',
    padding: 20,
    textAlign: 'center',
    marginVertical: 20,
  },
  fileLabel: {
    cursor: 'pointer',
    color: 'blue',
    textDecorationLine: 'underline',
    marginTop: 10,
  },
  uploadedFilesContainer: {
    marginTop: 20,
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  fileListItem: {
    marginBottom: 5,
  },
});

export default FileUploadComponent;