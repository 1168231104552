import axios from 'axios';
import baseUrl from "./constants/constants";

export async function uploadExerciseVideo(
  exerciseType,
  subjectId,
  therapistId,
  site,
  pain,
  rom,
  authToken,
  videoURI,
  isSnapshot,
  onProgress
) {
  // CREATE A NEW SESSION
  let newSession = {
    exercise_type: exerciseType,
    subject_id: subjectId,
    therapist_id: therapistId,
    site: site,
    pain: pain,
    rom: rom,
    snapshot: isSnapshot,
  };

  let reqHeaders = {
    "Content-Type": "application/json",
    Accept: "*/*",
    "Access-Control-Allow-Headers": "*",
    authorization: "Bearer " + authToken,
  };

  const createdSession = await fetch(baseUrl + "session/start", {
    method: "POST",
    headers: reqHeaders,
    withCredentials: true,
    body: JSON.stringify(newSession),
  })
    .then((response) => response.json())
    .then((response) => {
      var updatedSession = response[0];
      console.log("API Hit", JSON.stringify(updatedSession));
      return updatedSession;
    });

  var vidName = exerciseType + "/" + createdSession.session_id + ".mov";

  // GET S3 URL
  reqHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": baseUrl + "aws/uploadVideoLink",
    authorization: "Bearer " + authToken,
  };

  const s3url = await fetch(baseUrl + "aws/uploadVideoLink", {
    method: "POST",
    headers: reqHeaders,
    withCredentials: true,
    body: JSON.stringify({ vidName: vidName }),
  })
    .then((response) => response.json())
    .then((response) => {
      console.log("-------GET S3URL-------", JSON.stringify(response));
      return response.s3URL;
    });
  console.log("---------1---------: S3 URL FOR upload Session");

  console.log("new S3 URL:", s3url);

  // GET VIDEO RAW BLOB
  const fetchVidFromURI = async () => {
    var response = await fetch(videoURI);
    console.log("FETCH RESOURCE URI", JSON.stringify(response));
    var blob = await response.blob();
    return blob;
  };

  const vidBlob = await fetchVidFromURI();
  console.log("vidBlob: ", vidBlob);

  // UPLOAD VIDEO TO S3
  await axios.put(s3url, vidBlob, {
    headers: {
      'Content-Type': vidBlob.type,
      Accept: '*/*',
    },
    onUploadProgress: (p) => {
      const progress = p.loaded / p.total;
      onProgress(progress);
    },
  }).then((response) => {
    if (response.status === 200) {
      console.log("---------3---------:UPLOAD SUCCESSFUL", JSON.stringify(response));
    } else {
      console.log("ERROR Uploading Video");
    }
    console.log("try to split URL", s3url.split("?"));
    return s3url.split("?")[0];
  });

  // END SESSION
  const endSession = async (sessionId, s3URL, authToken) => {
    let endSession = {
      session_id: sessionId,
      s3_url: s3URL,
    };

    let reqHeaders = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Origin": baseUrl + "session/end",
      authorization: "Bearer " + authToken,
    };
    console.log("end session pre-send:", endSession);

    return fetch(baseUrl + "session/end", {
      method: "POST",
      headers: reqHeaders,
      withCredentials: true,
      body: JSON.stringify(endSession),
    })
      .then((response) => response.json())
      .then((response) => {
        var newSession = response[0];
        console.log(JSON.stringify(response));
        console.log("---------6---------: session has been uploaded upload ");
        return newSession;
      });
  };

  await endSession(createdSession.session_id, s3url.split("?")[0], authToken);

  return createdSession.session_id;
}