import React, { useContext, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { UploadContext } from '../contexts/UploadContext'

const ToastNotification = () => {
  const { uploadQueue } = useContext(UploadContext)

  useEffect(() => {
    uploadQueue.forEach((task) => {
      if (!task.toastId) {
        task.toastId = toast(`Uploading ${task.file.name}, do not close window or navigate to homepage`,{ progress: task.progress})
      } else {
        toast.update(task.toastId, { progress: task.progress })
      }
      if (task.progress === 1) {
        toast.update(task.toastId, {
          render: `Upload of ${task.file.name} completed`,
          type: 'success',
          autoClose: 5000,
        })
      }
    })
  },[uploadQueue])

  return <ToastContainer />
}

export default ToastNotification