import React, {useState} from 'react';
import DropDownPicker from 'react-native-dropdown-picker';
import {View,StyleSheet, Text} from 'react-native';

//https://hossein-zare.github.io/react-native-dropdown-picker-website/docs/usage

const Dropdown = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  var [placeholder, setPlaceholder] = useState('Exercise Type');

  var [items, setItems] = useState([
    {label: 'Double RDL', value: 'doubleRDL'},
    {label: 'Single RDL', value: 'singleRDL'},
    {label: 'External Rotator Cuff Rotation', value: 'rotatorCuff'},
    {label: 'Shoulder Taps', value: 'shoulderTaps'},
    {label: 'Single Leg Mini Squat', value: 'singleMiniSquat'}
    ]);

  const valueChanged = (value) => {
    setValue(value)
    props.value = value
  }
  if (props.type === 'Feedback'){
     [items, setItems] = useState([
        {label: 'Elbow                                        ', value: 'doubleRDL'},
        {label: 'Trunk', value: 'singleRDL'},
        {label: 'Hips', value: 'rotatorCuff'},
        {label: 'Arm', value: 'shoulderTaps'},
        {label: 'Other', value: 'singleMiniSquat'}
    ]);
    [placeholder, setPlaceholder] = useState('Feedback Type');
  } 

  function getFeedbackValue(){
      
  }
    
  
  
  

  return (
      <View style={styles.container}>
        <Text>{props.type}</Text>
        <DropDownPicker
        multiple=
      {(props.type === 'Feedback')?(true):(false)}
        textStyle={styles.text}
        dropDownContainerStyle={styles.list}
        listItemContainer={{
            width: '100%'
          }}
        style={styles.dropdown}
        bottomOffset={100}
        maxHeight={100}
        placeholderStyle={{
            //   backgroundColor: "#fff",
            fontWeight: "bold"
          }}
        open={open}
        placeholder={placeholder}
        value={value}
        items={items}
        setOpen={setOpen}
        setValue={setValue}
        setItems={setItems}
        dropDownDirection='BOTTOM'
        />
    </View>
  );
}

const styles = StyleSheet.create({
    container:{
        height: '100%',
        marginBottom:'100px'
    },
    list:{
        backgroundColor:'white',
        borderStyle:'none',
        alignItems: 'center',
        padding: 1,
        margin: 1,
        width: '100%',
    },
    text: {
        fontSize: 15,
        margin: 2,
        alignItems: 'center',
    },
    dropdown:{
        padding:20,
        margin: 5,
        alignItems: 'center',
        backgroundColor: "#EEEEEE", 
        width: '100%',         
    }
})

export default Dropdown;