import React, { useState } from "react";
import { Button, StyleSheet, Text, TextInput, View } from "react-native";
import Video from "./Video";

import {
    S3Client,
    CreateBucketCommand,
    DeleteBucketCommand,
    ListObjectsCommand
} from "@aws-sdk/client-s3";

import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";


//https://docs.aws.amazon.com/cognito/latest/developerguide/identity-pools.html
// AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//     IdentityPoolId: 'IDENTITY_POOL_ID',
//     Logins: {
//         'www.amazon.com': 'Amazon Access Token'
//     }
//  });


/**
 * Authentication Component
 * @description This component interacts with AWS S3 to list and display videos from a specified bucket.
 */
const Authentication = (props) => {
    const [bucketName, setBucketName] = useState("pt-exercise-videos");
    const [successMsg, setSuccessMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [bucketItems, setBucketItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState("");

    // Replace REGION with the appropriate AWS Region, such as 'us-east-1'.
    const region = "us-east-1";
    const client = new S3Client({
    region,
    credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region }),
        // Replace IDENTITY_POOL_ID with an appropriate Amazon Cognito Identity Pool ID for, such as 'us-east-1:xxxxxx-xxx-4103-9936-b52exxxxfd6'.
        identityPoolId: "us-east-1:434d5c83-a504-44ab-a0ab-956322714a59",
    }),
    });

    /**
     * Creates an S3 bucket with the specified name.
     */
    const createBucket = async () => {
    setSuccessMsg("");
    setErrorMsg("");

    try {
        await client.send(new CreateBucketCommand({ Bucket: bucketName },{
            mode:'no-cors',
            headers: {
                'Access-Control-Allow-Origin': '*', // Your headers
              }
        
        }));
        setSuccessMsg(`Bucket "${bucketName}" created.`);
    } catch (e) {
        setErrorMsg(e);
    }
    };


    /**
     * Sets the selected video item.
     * @param {string} key - The key of the selected video item.
     */

    const videoDetails= async (key) => {
        setSelectedItem(key);
        console.log(key)
    }


    /**
     * Lists the objects (videos) in the specified S3 bucket.
     */

    const seeVideos = async () => {
        try {
          const data = await client.send(new ListObjectsCommand({Bucket: bucketName}));
          console.log("Success", JSON.stringify(data));
          setSelectedItem("");
          setBucketItems(data.Contents) ; // For unit tests.
        } catch (err) {
          console.log("Error", err);
        }
      };



    /**
     * Deletes the specified S3 bucket.
     */
    const deleteBucket = async () => {
    setSuccessMsg("");
    setErrorMsg("");

    try {
        await client.send(new DeleteBucketCommand({ Bucket: bucketName }));
        setSuccessMsg(`Bucket "${bucketName}" deleted.`);
    } catch (e) {
        setErrorMsg(e);
    }
    };

    console.log("selectedItem = ", selectedItem)

    return (
    <View style={styles.container}>
        <Text style={{ color: "green" }}>
        {successMsg ? `Success: ${successMsg}` : ``}
        </Text>
        <Text style={{ color: "red" }}>
        {errorMsg ? `Error: ${errorMsg}` : ``}
        </Text>
        <View>
        <TextInput
            style={styles.textInput}
            onChangeText={(text) => setBucketName(text)}
            autoCapitalize={"none"}
            value={bucketName}
            placeholder={"Enter Bucket Name"}
        />
        {/* <Button
            backroundColor="#68a0cf"
            title="Create Bucket"
            onPress={createBucket}
        /> */}
        {/* <Button
            backroundColor="#68a0cf"
            title="Delete Bucket"
            onPress={deleteBucket}
        /> */}
        <Button
            backroundColor="#68a0cf"
            title="See Videos"
            onPress={seeVideos}
        />
        {(selectedItem === "")? (
            
            bucketItems.map((item) => 
            <View>
            <Button key={item.Key} title={item.Key} onPress={() => videoDetails(item.Key)}></Button>
            </View> )

        ): (
            <View>
                <Text>{selectedItem}</Text>
                <Video
                    source={"https://pt-exercise-videos.s3.amazonaws.com/" + selectedItem}
                    // poster={'https://www.fillmurray.com/480/300'}
                />
            </View>

           
        )}
        
          
        </View>
    </View>
    );
};

const styles = StyleSheet.create({
    container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    margin: 10,
    },
});

export default Authentication